
<template>
    <div class="text-left bg-white-alt">
      <NavBarNew :greenLogo='true'/>
      <main class="tr-container">
        <h1>{{ $t("principlesDataProcessing.title") }}</h1>
        <section class="topics-section">
            <p>{{ $t("principlesDataProcessing.introductionText1") }}</p>
            <p>{{ $t("principlesDataProcessing.introductionText2") }}</p>
            <h4>{{ $t("principlesDataProcessing.topics") }}</h4>
            <ol>
                <li><router-link to="#whatDataDoWeCollect">{{ $t("principlesDataProcessing.whatDataDoWeCollect") }}</router-link></li>
                <li><router-link to="#howDoWeCollectYourData">{{ $t("principlesDataProcessing.howDoWeCollectYourData") }}</router-link></li>
                <li><router-link to="#howWillWeUseYourData">{{ $t("principlesDataProcessing.howWillWeUseYourData") }}</router-link></li>
                <li><router-link to="#howDoWeStoreYourData">{{ $t("principlesDataProcessing.howDoWeStoreYourData") }}</router-link></li>
                <li><router-link to="#whatAreYourDataProtectionRights">{{ $t("principlesDataProcessing.whatAreYourDataProtectionRights") }}</router-link></li>
                <li><router-link to="#whatAreCookies">{{ $t("principlesDataProcessing.whatAreCookies") }}</router-link></li>
                <li><router-link to="#howDoWeUseCookies">{{ $t("principlesDataProcessing.howDoWeUseCookies") }}</router-link></li>
                <li><router-link to="#whatTypesOfCookiesDoWeUse">{{ $t("principlesDataProcessing.whatTypesOfCookiesDoWeUse") }}</router-link></li>
                <li><router-link to="#howToManageYourCookies">{{ $t("principlesDataProcessing.howToManageYourCookies") }}</router-link></li>
                <li><router-link to="#privacyPoliciesOfOtherWebsites">{{ $t("principlesDataProcessing.privacyPoliciesOfOtherWebsites") }}</router-link></li>
                <li><router-link to="#changesToOurPersonalDataProcessingPolicy">{{ $t("principlesDataProcessing.changesToOurPersonalDataProcessingPolicy") }}</router-link></li>
                <li><router-link to="#howToContactUs">{{ $t("principlesDataProcessing.howToContactUs") }}</router-link></li>
                <li><router-link to="#howToContactTheAppropriateAuthorities">{{ $t("principlesDataProcessing.howToContactTheAppropriateAuthorities") }}</router-link></li>
            </ol>
        </section>
        <section id="whatDataDoWeCollect">
            <h3>{{ $t("principlesDataProcessing.whatDataDoWeCollect") }}</h3>
            <p>{{ $t("principlesDataProcessing.trekioCollectsFollowingData") }}</p>
            <ul>
                <li>{{ $t("principlesDataProcessing.personalIdentificationInformation") }}
                    <ul>
                        <li>{{ $t("principlesDataProcessing.personalIdentificationInformationUsers") }}</li>
                        <li>{{ $t("principlesDataProcessing.personalIdentificationInformationAuthors") }}</li>
                    </ul>
                </li>
                <li>
                    {{ $t("principlesDataProcessing.personalInformationInYourContent") }}
                </li>
            </ul>
        </section>
        <section id="howDoWeCollectYourData">
            <h3>{{ $t("principlesDataProcessing.howDoWeCollectYourData") }}</h3>
            <p>{{ $t("principlesDataProcessing.weCollectDataWhenYou") }}</p>
            <ul>
                <li>{{ $t("principlesDataProcessing.weCollectDataOption1") }}</li>
                <li>{{ $t("principlesDataProcessing.weCollectDataOption2") }}</li>
                <li>{{ $t("principlesDataProcessing.weCollectDataOption3") }}</li>
                <li>{{ $t("principlesDataProcessing.weCollectDataOption4") }}</li>
            </ul>
        </section>
        <section id="howWillWeUseYourData">
            <h3>{{ $t("principlesDataProcessing.howWillWeUseYourData") }}</h3>
            <p>{{ $t("principlesDataProcessing.dataCollectionPurpose") }}</p>
            <ul>
                <li>{{ $t("principlesDataProcessing.dataCollectionPurposeText") }}</li>
            </ul>
        </section>
        <section id="howDoWeStoreYourData">
            <h3>{{ $t("principlesDataProcessing.howDoWeStoreYourData") }}</h3>
            <p>{{ $t("principlesDataProcessing.howDoWeStoreYourDataText1") }}</p>
            <p>{{ $t("principlesDataProcessing.howDoWeStoreYourDataText2") }}</p>
            <p>{{ $t("principlesDataProcessing.howDoWeStoreYourDataText3") }}</p>
        </section>
        <section id="whatAreYourDataProtectionRights">
            <h3>{{ $t("principlesDataProcessing.whatAreYourDataProtectionRights") }}</h3>
            <p>{{ $t("principlesDataProcessing.whatAreYourDataProtectionRightsText1") }}</p>
            <p>{{ $t("principlesDataProcessing.whatAreYourDataProtectionRightsText2") }}</p>
            <p>{{ $t("principlesDataProcessing.whatAreYourDataProtectionRightsText3") }}</p>
            <p>{{ $t("principlesDataProcessing.whatAreYourDataProtectionRightsText4") }}</p>
            <p>{{ $t("principlesDataProcessing.whatAreYourDataProtectionRightsText5") }}</p>
            <p>{{ $t("principlesDataProcessing.whatAreYourDataProtectionRightsText6") }}</p>
            <p>{{ $t("principlesDataProcessing.whatAreYourDataProtectionRightsText7") }}</p>
            <p>{{ $t("principlesDataProcessing.whatAreYourDataProtectionRightsText8") }}</p>
            <p>{{ $t("principlesDataProcessing.whatAreYourDataProtectionRightsText9") }}</p>
        </section>
        <section id="whatAreCookies">
            <h3>{{ $t("principlesDataProcessing.whatAreCookies") }}</h3>
            <p>{{ $t("principlesDataProcessing.whatAreCookiesText") }}</p>
            <p>{{ $t("principlesDataProcessing.whatAreCookiesMoreInfo") }}</p>
        </section>
        <section id="howDoWeUseCookies">
            <h3>{{ $t("principlesDataProcessing.howDoWeUseCookies") }}</h3>
            <p>{{ $t("principlesDataProcessing.weUseCookiesFor") }}</p>
            <ul>
                <li>{{ $t("principlesDataProcessing.weUseCookiesForOption1") }}</li>
                <li>{{ $t("principlesDataProcessing.weUseCookiesForOption2") }}</li>
                <li>{{ $t("principlesDataProcessing.weUseCookiesForOption3") }}</li>
            </ul>
        </section>
        <section id="whatTypesOfCookiesDoWeUse">
            <h3>{{ $t("principlesDataProcessing.whatTypesOfCookiesDoWeUse") }}</h3>
            <p>{{ $t("principlesDataProcessing.weUseTheseCookies") }}</p>
            <ul>
                <li>{{ $t("principlesDataProcessing.functionalCookies") }}</li>
            </ul>
            <p>{{ $t("principlesDataProcessing.weMightUseTheseCookiesInTheFuture") }}</p>
            <ul>
                <li>{{ $t("principlesDataProcessing.preferentialCookies") }}</li>
                <li>{{ $t("principlesDataProcessing.statisticalCookies") }}</li>
                <li>{{ $t("principlesDataProcessing.marketingCookies") }}</li>
                <li>{{ $t("principlesDataProcessing.unclassifiedCookies") }}</li>
            </ul>
        </section>
        <section id="howToManageYourCookies">
            <h3>{{ $t("principlesDataProcessing.howToManageYourCookies") }}</h3>
            <p>{{ $t("principlesDataProcessing.howToManageYourCookiesText") }}</p>
        </section>
        <section id="privacyPoliciesOfOtherWebsites">
            <h3>{{ $t("principlesDataProcessing.privacyPoliciesOfOtherWebsites") }}</h3>
            <p>{{ $t("principlesDataProcessing.privacyPoliciesOfOtherWebsitesText") }}</p>
        </section>
        <section id="changesToOurPersonalDataProcessingPolicy">
            <h3>{{ $t("principlesDataProcessing.changesToOurPersonalDataProcessingPolicy") }}</h3>
            <p>{{ $t("principlesDataProcessing.changesToOurPersonalDataProcessingPolicyText") }}</p>
        </section>
        <section id="howToContactUs">
            <h3>{{ $t("principlesDataProcessing.howToContactUs") }}</h3>
            <p>{{ $t("principlesDataProcessing.howToContactUsText") }}</p>
            <p>{{ $t("principlesDataProcessing.emailUs") }}</p>
            <p>{{ $t("principlesDataProcessing.contactUsViaContactForm") }}</p>
        </section>
        <section id="howToContactTheAppropriateAuthorities">
            <h3>{{ $t("principlesDataProcessing.howToContactTheAppropriateAuthorities") }}</h3>
            <p>{{ $t("principlesDataProcessing.howToContactTheAppropriateAuthoritiesText") }}</p>
        </section>
        <p>{{ $t("principlesDataProcessing.theseTermsTakeEffectOn") }}</p>
  
      </main>
      <Footer/>
    </div>
  </template>
  
  <script>
  import Footer from "@/components/Footer";
  
  export default {
  
    name: "PrinciplesDataProcessingPage",
    components: {
      Footer
    }
  }
  </script>
  
  <style scoped lang="scss">
      @import '@/scss/variables';

    .topics-section a {
        color: $tr-black;
    }

    main {
        padding-top: 20px;
        margin-bottom: 60px;
        scroll-margin: 0;
        scroll-margin-top: 0 !important;
    }

    h1 {
        margin-bottom: 40px;
    }

    section {
        padding-bottom: 40px;
        scroll-margin-top: 0 !important;
    }
    
  </style>